import Request from '../Request.utils'
export interface ICheckDownloadBillingRequestParams {
  productId?: string;
  quantity?: number;
  variantName?: string;
}
export interface IStripeCreatePaymentRequestBody {
  amount?: number;
  paymentMethodId?: string;
  productId?: string;
  variationAmount?: number;
  variationName?: string;
  variationPrice?: number;
}
export interface IMarkOrderCompleteRequestBody {
  amount?: number;
  fulfillAmount?: number;
  price?: number;
  printJobId?: string;
}
export interface IRejectOrderRequestBody {
  orders?: any[];
  reason?: string;
}
export interface ICreateInvoiceRequestBody {
  order?: any;
}
export interface IOrderInvoicesRequestQuery {
  month?: string;
  resellerId?: string;
}
export interface IUpdateInvoiceRequestBody {
  fulfilledAt?: string;
  orderId: string;
  paidAt?: string;
}
export interface IGenOrderInvoiceRequestParams {
  orderId: string;
}
export interface ICancelOrderRequestBody {
  orders?: any[];
}
export interface IUpdateOrderInvoiceManuallyRequestBody {
  id: string;
  url?: string;
}
export interface IManuallyOverrideInvoiceRequestBody {
  invoiceId?: string;
  manualInvoicePdf?: string;
  postage?: number;
  productPrice?: number;
  total?: number;
  vat?: number;
}


class OrderAPI {
  checkDownloadBilling = async (params: ICheckDownloadBillingRequestParams) => {
    const res = await Request.call('/api/orders/check-download-billings/:productId', 'POST', params, undefined, undefined, );
    return res;
  }
  stripeCreatePayment = async (body: IStripeCreatePaymentRequestBody) => {
    const res = await Request.call('/api/orders/create-payment', 'POST', undefined, undefined, body, );
    return res;
  }
  markOrderComplete = async (body: IMarkOrderCompleteRequestBody) => {
    const res = await Request.call('/api/orders/mark-order-complete', 'POST', undefined, undefined, body, );
    return res;
  }
  rejectOrder = async (body: IRejectOrderRequestBody) => {
    const res = await Request.call('/api/orders/reject', 'POST', undefined, undefined, body, );
    return res;
  }
  createInvoice = async (body: ICreateInvoiceRequestBody) => {
    const res = await Request.call('/api/order/invoice', 'POST', undefined, undefined, body, );
    return res;
  }
  orderInvoices = async (query: IOrderInvoicesRequestQuery) => {
    const res = await Request.call('/api/order/invoices', 'GET', undefined, query, undefined, );
    return res;
  }
  updateInvoice = async (body: IUpdateInvoiceRequestBody) => {
    const res = await Request.call('/api/order/update-invoice', 'POST', undefined, undefined, body, );
    return res;
  }
  genOrderInvoice = async (params: IGenOrderInvoiceRequestParams) => {
    const res = await Request.call('/api/order/gen-invoice/:orderId', 'GET', params, undefined, undefined, );
    return res;
  }
  cancelOrder = async (body: ICancelOrderRequestBody) => {
    const res = await Request.call('/api/orders/cancel', 'POST', undefined, undefined, body, );
    return res;
  }
  updateOrderInvoiceManually = async (body: IUpdateOrderInvoiceManuallyRequestBody) => {
    const res = await Request.call('/api/orders/update-invoice-manually', 'POST', undefined, undefined, body, );
    return res;
  }
  manuallyOverrideInvoice = async (body: IManuallyOverrideInvoiceRequestBody) => {
    const res = await Request.call('/api/orders/manually-override-invoice', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new OrderAPI()