import React, { useEffect, useMemo, useRef } from 'react';
import { CMSLayout, Col, Row, Text, Button, useUIState, useRefState, showPopupMessage } from 'components';
import { usePaymentStore } from 'store/Payment.Store';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SettingsBilling from './Settings/Settings.Billing';
import Store from 'store';
import { SettingsFormProvider, useSettingsForm } from './Settings/FormContext';


const MyWallet = () => {
  const billingRef = useRef<{ submit: Function, shouldCreateStripeCustomer: () => boolean }>();
  const [{ loading: submitting }, setSubmitUI] = useUIState();
  const { stripePromise, getStripeConfig } = usePaymentStore();
  const { billingInformation } = useSettingsForm();

  const UserStore = Store.useUserStore();
  const { user, setUser, uiState } = UserStore.useUser(
    UserStore.user?.id,
    false
  );
  const [resellerId, getResellerId, setResellerId] = useRefState('');

  useEffect(() => {
    getStripeConfig();
  }, [])

  useEffect(() => {
    setResellerId(UserStore.getResellerId(user));
  }, [user]);

  const submit = async () => {
    try {
      setSubmitUI({ loading: true });
      await billingRef?.current?.submit();
      const { firstName, lastName, addressLine1, addressLine2, city, postcode, country, currency } = billingInformation;
      console.log('billingInformation', billingInformation);
      if (
        !firstName ||
        !lastName ||
        !city ||
        !postcode ||
        !country ||
        !addressLine1
      ) {
        return showPopupMessage({
          title: '',
          content: 'Please fill in all required fields',
          buttonOkText: 'OK',
          typeHighlight: 'danger',
          contentHighlight: 'Error'
        });
      }
      const res = await Store.Api.User.upsertReseller({
        id: getResellerId(),
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        town: city,
        postCode: postcode,
        country,
        currency,
      });
      if (res.data.error) {
        showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',

          typeHighlight: 'danger',
          contentHighlight: 'Error'

        });
        // alert(res.data.error);
      } else if (res.data.data) {
        showPopupMessage({
          title: '',
          content: 'Billing info successfully updated.',
          buttonOkText: 'OK',

          typeHighlight: 'success',
          contentHighlight: 'Success'

        });
        // alert("Billing info successfully updated");
      }
    } catch (e) {
    } finally {
      setSubmitUI({ loading: false });
    }
  }

  return (
    <CMSLayout requireAuthen>
        {stripePromise && (
          <Elements stripe={stripePromise}>
            <Row m2 marginBottom={0} justifyContent={'space-between'}>
              <Text h3>My Wallet</Text>
              <Row>
                <Button
                  isLoading={submitting}
                  text={"Save"}
                  width={100} height={40} borderRadius={20}
                  onPress={submit}
                />
              </Row>
            </Row>
            <Col flex1>
              <SettingsBilling ref={billingRef} m2 mv1 p1 round1 bgWhite />
            </Col>
          </Elements>
        )}
    </CMSLayout>
  );
};

const MyWalletWithContext = (props) => {
  return (
    <SettingsFormProvider>
      <MyWallet {...props} />
    </SettingsFormProvider>
  )
}

MyWalletWithContext.routeInfo = {
  title: 'My Wallet',
  path: '/wallet'
};

export default MyWalletWithContext;
