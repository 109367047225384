import React from 'react';
import { Col, Text, modal } from 'components';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, COLORS } from 'const';
import Store from 'store';
import { useWindowDimensions } from 'react-native';
import { ValHelper } from 'helpers';
import { Button } from 'antd';

const EditDesignModal = ({ designId, onDesignSave }: any) => {
  const { width, height } = useWindowDimensions();
  const rV = useDynamicResponsiveValue(["xs", "md"]);
  const breakpoint = rV({ xs: "xs", md: "md" });

  const User = Store.useUserStore();

  const onEditorMessage = async (
    data: { event: string; payload: any } | undefined
  ) => {
    console.log("onEditorMessage", data);
    if (!!data && data.event === "CLOSE_EDITOR") {
      // close modal
      modal.hide();
    } else if (!!data && data.event === "DESIGN_SAVE") {
      // close modal
      modal.hide();
      // update design
      !!onDesignSave && onDesignSave(data.payload.designId);
    }
  };

  return (
    <Col width={width * 0.95} height={height * 0.95} round1 bgWhite shadow overflow={'hidden'}>
      {false ?
        <Col flex1 middle>
          <Text mb2>Please use bigger screen to see this page.</Text>
          <Button
            size="large"
            type="primary"
            onClick={() => modal.hide()}
            style={{ backgroundColor: COLORS.BLUE }}
          >
            <Text fontSize={16} fontWeight="600" colorWhite>Close</Text>
          </Button>
        </Col>
        :
        <Col flex1>
          <iframe
            src={
              window.location.href.includes("iframe_dev=1")
                ? `http://localhost:3009/design/${designId}?admin=1&userId=${User.user?.id}${ValHelper.isDev ? "&dev=1" : ""}`
                : `${ValHelper.editorHost}/design/${designId}?admin=1&userId=${User.user?.id}${ValHelper.isDev ? "&dev=1" : ""}`
            }
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              backgroundColor: COLOR.MAIN,
            }}
            onLoad={Store.Client.editor.initOnLoadAndOpenCommunication(
              onEditorMessage
            )}
          />
        </Col>
      }
    </Col>
  );
}

export default EditDesignModal;
